@import 'var';

.orderlist{
    margin: 0;
    margin-left: 30px;
    margin-right: 30px;
    padding: 0;
    list-style-type: none;
    width: 200px;
    li{
        margin-top: 5px;
        &:first-child{
            margin: 0;
        }
    }
}

.order{
    min-height: 48px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    border-radius: 2px;
    transition: $transition-half;
    position: relative;

    &__toggle{
        flex: 0 0 52px;
        box-shadow: inset 52px 0px 0px 0px #3271fe;
        transition: $transition;
        position: relative;
        color: #fff;
        .material-icons{
            position: absolute;
            bottom: 19px;
            left: 14px;
            cursor: pointer;
            transition: $transition;
        }
    }
    &__inner{
        flex-grow: 3;
        display: flex;
        border-bottom: 1px solid $dividing;
        .material-icons--pict{
            font-size: 18px;
            color: $main;
            padding-top: 8px;
            padding-left: 6px;
        }
    }
    &__inner-line{
        border-bottom: none;
    }
    &__close{
        display: flex;
        flex: 0 0 50px;
        justify-content: center;
        position: relative;
        .material-icons{
            cursor: pointer;
            color: $dividing;
            position: absolute;
            top: 22px;
            left: 50%;
            transform: translate(-50%,0);
            &:after{
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                box-sizing: border-box;
                z-index: 9;
                border: 0;
                border-radius: 50%;
                transition: $transition;
            }
            &:hover:after{
                width: 38px;
                height: 38px;
                border: 1px solid #dee2ed;
                transition: $transition;
            }
        }
    }
    &__more{
        flex: 0 0 35px;
        max-height: 54px;
        display: flex;
        justify-content: center;
        transition: $transition;
        margin-right: 4px;
        .material-icons{
            align-self: center;
            cursor: pointer;
            color: $main;
            position: relative;

            &:after{
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                box-sizing: border-box;
                z-index: 9;
                border: 0;
                border-radius: 50%;
                transition: $transition;
            }

            &:hover:after{
                width: 38px;
                height: 38px;
                border: 1px solid $light_blue;
                transition: $transition;
            }
        }
        .material-icons-position{
            align-self: flex-start;
        }
    }
    &__more-position{
        margin-top: 15px;
    }
    &__info{
        flex-grow: 3;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 130px;
        overflow: hidden;
        position: relative;
        padding: 8px 6px;
            &:after{
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 10px;
                background: linear-gradient(to right, rgba(255,255,255, 0.2), #fff 100%);
            }
        &-plus{
            cursor: pointer;
            border: 1px solid $muted_grey;
            color: $muted_grey;
            position: absolute;
            right: 190px;
            top: 16px;
            z-index: 9;
            border-radius: 3px;
            background-color: #fff;
            width: 20px;
            height: 20px;
            padding: 1px;
        }
        &-plus-name{
            align-self: self-start;
            font-size: 12px;
            font-weight: 500;
            text-overflow: ellipsis;
            max-width: 170px;
            transition: $transition-half;
            color: $basic_grey;
            padding-top: 8px;
            border-top: 1px solid $dividing;
            width: 200px;
            &:nth-child(1){
                margin-top: 8px;
            }
        }
        &-name{
            align-self: self-start;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 170px;
            overflow: hidden;
            transition: $transition-half;
            color: $muted_grey;
        }
        &-plus-tel{
            align-self: self-start;
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
            max-width: 170px;
            transition: $transition-half;
            color: $muted_grey;
            padding-bottom: 8px;
        }
        &-tel{
            align-self: self-start;
            font-size: 12px;
            font-weight: 400;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 170px;
            overflow: hidden;
            transition: $transition-half;
            color: $muted_grey;
        }
        &__closed{
            display: none;
        }
        &__opened{
            display: block;
        }
    }
    &__icon{
        opacity: 0;
        visibility: hidden;
        height: 0;
        overflow: hidden;
        position: absolute;
        top: 27px;
        transition: $transition;
        z-index: 100;
        left: 25px;
        .material-icons{
            color: $corporate_blue;
            font-size: 32px;
        }
    }
    &__add-link{
        opacity: 0;
        visibility: hidden;
        height: 0;
        overflow: hidden;
        transition: $transition;
        color: $corporate_blue;
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;
        display: flex;
        justify-content: flex-end;
        .material-icons{
            align-self: center;
            margin-right: 10px;
            position: relative;
            &:after{
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                box-sizing: border-box;
                z-index: 9;
                border: 0;
                border-radius: 50%;
                transition: $transition;
            }
            &:hover:after{
                width: 38px;
                height: 38px;
                border: 1px solid $light_blue;
                transition: $transition;
            }
        }
        span{
            align-self: center;
        }
    }
    &_article{
        .order__info-name, .order__info-tel{
            max-width: 172px;
        }
    }
}
.order_black.order_article{
    .order__info-tel, .order__info-name{
        color: $basic_grey;
        font-weight: 600;
    }
}
.order_blue.order_article{
    .order__info-tel, .order__info-name{
        color: $main;
    }
    border: 1px solid $main;
    .order__inner{
        .material-icons--pict{
            color: $main;
        }
        .order__more{
            .material-icons{
                color: $main;
                &:hover:after{
                    border: 1px solid $main;
                }
            }
        }
    }
}
.order_green.order_article{
    .order__info-tel, .order__info-name{
        color: $dark_green;
    }
    border: 1px solid $dark_green;
    .order__inner{
        .material-icons--pict{
            color: $dark_green;
        }
        .order__more{
            .material-icons{
                color: $dark_green;
                &:hover:after{
                    border: 1px solid $dark_green;
                }
            }
        }
    }
}
.order_red.order_article{
    .order__icon .material-icons, .order__info-name, .order__info-tel{
        color: $pink;
    }
    border: 1px solid $pink;
    .order__inner{
        .material-icons--pict{
            color: $pink;
        }
        .order__more{
            .material-icons{
                color: $pink;
                &:hover:after{
                    border: 1px solid $pink;
                }
            }
        }
    }
}

.order_grey.order_article{
    .order__icon .material-icons, .order__info-name, .order__info-tel{
        color: $muted_grey;
    }
    border: 1px solid $muted_grey;
    align-items: flex-start;
}

.dropdown {
    right: -4px;
    top: 10px;
    width: 216px;
    cursor: pointer;
    &-item {
        & span{
            flex-grow: 3;
        }
        
        .material-icons{
            color: $main !important;
            &--more{
                color: $passive_grey !important;
                padding-right: 13px;
                font-size: 24px !important;
            }
            &:after{
                display: none;
            }
        }
        
        &--row{
            flex-grow: 3;
        }

        & img{
            width: 20px;
            height: 20px;
        }

        &:hover {
            color: #fff;
            background-color: $main;
            .material-icons{
                color: #fff !important;
            }             
            span{
                color: #fff !important;
            }
            img{
                filter: brightness(0) invert(1);
            }
        }
    }
    &-item-red{
        & span{
            color: $pink !important;
        }
        & .material-icons{
            color: $pink !important;
        }
        &:hover{
            color: #fff;
            background-color: $pink;
        }
    }

    &:after {
        display: none;
    }

    &_radio {
        max-height: 200px;
        margin: 0;
    }

    &_radio-position{
        margin-top: -16px;
    }

    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $corporate_blue;
        font-size: 14px;
        padding-left: 25px;
    }   
}